window.addEventListener('load', () => handleSideMenu());

const handleSideMenu = (): void => {
  const sideMenuBackDrop: HTMLElement = document.getElementById('side-menu-backdrop');
  const sideMenuBackOpenTrigger: HTMLElement = document.getElementById('side-menu-open-trigger');
  const sideMenuBackCloseTrigger: HTMLElement = document.getElementById('side-menu-close-trigger');

  sideMenuBackDrop.addEventListener('click', () => toggleSideMenu());

  sideMenuBackDrop.addEventListener('keydown', (event: KeyboardEvent) => {
    if (event.key !== 'Enter') {
      return;
    }

    toggleSideMenu();
  });

  sideMenuBackOpenTrigger.addEventListener('click', () => toggleSideMenu());

  sideMenuBackOpenTrigger.addEventListener('keydown', (event: KeyboardEvent) => {
    if (event.key !== 'Enter') {
      return;
    }

    toggleSideMenu();
  });

  sideMenuBackCloseTrigger.addEventListener('click', () => toggleSideMenu());

  sideMenuBackCloseTrigger.addEventListener('keydown', (event: KeyboardEvent) => {
    if (event.key !== 'Enter') {
      return;
    }

    toggleSideMenu();
  });

  Array.from(document.getElementsByClassName('side-menu-item')).forEach((sideMenuItem: HTMLElement) => {
    sideMenuItem.addEventListener('click', () => closeSideMenu());

    sideMenuItem.addEventListener('keydown', (event: KeyboardEvent) => {
      if (event.key !== 'Enter') {
        return;
      }

      closeSideMenu();
    });
  });

  document.addEventListener('keydown', (event: KeyboardEvent) => {
    if (event.key !== 'Esc' && !isSideMenuOpened()) {
      return;
    }

    toggleSideMenu();
  });
};

const isSideMenuOpened = (): boolean => !document.getElementById('side-menu-backdrop').classList.contains('hidden');

const toggleSideMenu = (): void => {
  if (isSideMenuOpened()) {
    return closeSideMenu();
  }

  return openSideMenu();
};

const openSideMenu = (): void => {
  document.getElementById('side-menu-backdrop').classList.remove('hidden');
  document.getElementById('side-menu-content').classList.remove('-right-full');
  document.getElementById('side-menu-content').classList.remove('sm:-right-[320px]');
  document.getElementById('side-menu-content').classList.add('right-0');
  document.body.classList.add('overflow-hidden');
};

const closeSideMenu = (): void => {
  document.getElementById('side-menu-backdrop').classList.add('hidden');
  document.getElementById('side-menu-content').classList.add('-right-full');
  document.getElementById('side-menu-content').classList.add('sm:-right-[320px]');
  document.getElementById('side-menu-content').classList.remove('right-0');
  document.body.classList.remove('overflow-hidden');
};
